"use client";
import Section from "@/components/new-components/wrapper/Section";
import { Button } from "@/components/ui/button";
import useNavStore from "@/lib/store/newStore/useNavStore";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import Hamburger from "./Hamburger";
import { useToast } from "@/components/ui/use-toast";
import { useGoogleOneTapLogin, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import useAuthStore from "@/lib/store/useAuthStore";
import useZustandStore from "@/lib/store/useZustandStore";
import useLoginWithOneTap from "@/lib/hooks/useLoginWithOneTap";
import useCheckLogin from "@/lib/hooks/useCheckLogin";
import NavMenu from "./NavMenu";
import { usePathname, useRouter } from "next/navigation";
import { ShoppingCart } from "lucide-react";
import useGetCartCount from "@/lib/hooks/useGetCartCount";
import { motion } from "framer-motion";
import { logEvent } from "@/analytics";

interface SessionData {
  device: string;
  full_name: string;
  last_updated: string;
  session_expiry: string;
  session_ip: string;
  user: string;
  user_type: string;
}

interface ApiResponse {
  full_name: string;
  home_page: string;
  jwt_token: string;
  message: {
    data: SessionData;
    sid: string;
    user: string;
  };
  token: string;
}

const Navbar = () => {
  const { cartQty, isLoading: cartQtyLoading } = useGetCartCount();
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const { setUserData, setToken, token } = useAuthStore((state) => state);
  const setUser = useZustandStore((state) => state.setUser);
  const { mutate: loginWithOneTapMutate } = useLoginWithOneTap();
  // const { data: isLoggedIn } = useCheckLogin();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const pathName = usePathname();
  const router = useRouter();

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  const handleLoginProcess = async (res: ApiResponse) => {
    if (res?.full_name) {
      toast({
        variant: "successGreen",
        title: "Logged In",
        description: "Welcome, " + res?.full_name,
      });
    }
    localStorage.setItem("token", res?.token);
    setToken(res?.token);
    localStorage.setItem("user", res?.full_name);
    let localUserData = await axios.get(
      `${
        process.env.NEXT_PUBLIC_API_URL
      }/api/method/bonatra.custom.latest_customer_v2?user=${localStorage.getItem(
        "email"
      )}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        withCredentials: true,
      }
    );
    setUserData(localUserData?.data?.message);
    setUser(res?.message?.data?.user);
    setIsLoading(false);
    // Reload the page after successful login
    window.location.reload();
  };

  const handleSuccess = async (credentialResponse) => {
    console.log('credentialResponse: ', credentialResponse)
    setIsLoading(true);
      let payload = {
        client_id: credentialResponse?.clientId,
        credential: credentialResponse?.credential,
        select_by: credentialResponse?.select_by,
        use_jwt: 1,
      };
      if (credentialResponse?.credential) {
        loginWithOneTapMutate(
          { payload: payload },
          {
            onSuccess: async (res: any) => {
              if (res?.data?.message?.signup) {
                loginWithOneTapMutate(
                  { payload: payload },
                  {
                    onSuccess: async (res: any) => {
                      handleLoginProcess(res?.data);
                    },
                    onError: (e) => {
                      setIsLoading(false);
                      toast({
                        title: "Error",
                        description:
                          "An error occurred while signing in with Google, please try later",
                        variant: "destructive",
                      });
                    },
                  }
                );
                return;
              }
              handleLoginProcess(res?.data);
              setIsLoading(false);
            },
            onError: (error: any) => {
              setIsLoading(false);
              if (error.response.status == 409) {
                toast({
                  title: "Error",
                  description: "Please sign up first",
                  variant: "destructive",
                });
                return;
              }
              toast({
                title: "Error",
                description:
                  "An error occurred while signing in with Google, please try later",
                variant: "destructive",
              });
            },
          }
        );
      }
  };

  const handleError = () => {
    console.log('login failed');
    setIsLoading(false);
    toast({
      title: "Error",
      description: "Login Failed",
      variant: "destructive",
    });
  };

  <GoogleLogin
    onSuccess={(credentialResponse) => handleSuccess(credentialResponse)}
    onError={handleError}
    useOneTap
  />;

  // useGoogleOneTapLogin({
  //   onSuccess: handleSuccess,
  //   onError: handleError,
  //   disabled: token !== null
  // })

  // useGoogleOneTapLogin({
  //   onSuccess: async (credentialResponse) => {
  //     setIsLoading(true);
  //     let payload = {
  //       client_id: credentialResponse?.clientId,
  //       credential: credentialResponse?.credential,
  //       select_by: credentialResponse?.select_by,
  //       use_jwt: 1,
  //     };
  //     if (credentialResponse?.credential) {
  //       loginWithOneTapMutate(
  //         { payload: payload },
  //         {
  //           onSuccess: async (res: any) => {
  //             if (res?.data?.message?.signup) {
  //               loginWithOneTapMutate(
  //                 { payload: payload },
  //                 {
  //                   onSuccess: async (res: any) => {
  //                     handleLoginProcess(res?.data);
  //                   },
  //                   onError: (e) => {
  //                     setIsLoading(false);
  //                     toast({
  //                       title: "Error",
  //                       description:
  //                         "An error occurred while signing in with Google, please try later",
  //                       variant: "destructive",
  //                     });
  //                   },
  //                 }
  //               );
  //               return;
  //             }
  //             handleLoginProcess(res?.data);
  //             setIsLoading(false);
  //           },
  //           onError: (error: any) => {
  //             setIsLoading(false);
  //             if (error.response.status == 409) {
  //               toast({
  //                 title: "Error",
  //                 description: "Please sign up first",
  //                 variant: "destructive",
  //               });
  //               return;
  //             }
  //             toast({
  //               title: "Error",
  //               description:
  //                 "An error occurred while signing in with Google, please try later",
  //               variant: "destructive",
  //             });
  //           },
  //         }
  //       );
  //     }
  //   },
  //   onError: () => {
  //     setIsLoading(false);
  //     toast({
  //       title: "Error",
  //       description: "Login Failed",
  //       variant: "destructive",
  //     });
  //   },
  //   disabled: token !== null
  // });

  const handleWebinarClick = () => {
    const component = document.querySelector("#leadsForm");
    component?.scrollIntoView({ behavior: "smooth" });
    logEvent("LandingPage", "Book a free consultation NavBar");
    (window as any).fbq("trackCustom", "Book a free consultation NavBar");
  };
  const burgerState = useNavStore((state) => state.burgerState);
  if (pathName === "/coach-consultation") return null;
  const webinarDisplay = pathName === "/programs/webinar";
  const handleLogin = () => {
    router.push("/login");
  };
  return (
    <div
      className={`bg-[#141416] sticky top-0 z-[999] text-white ${
        burgerState === true ? "h-screen overflow-hidden" : ""
      }`}
    >
      <Section>
        <nav className=" flex items-center justify-between py-4 sticky top-0">
          <div className="flex items-center gap-10">
            <Image
              src="https://assets.bonatra.com/Website%20Assets/White.webp"
              height={100}
              width={100}
              alt="logo"
              className="hover:cursor-pointer"
              onClick={() => router.push("/")}
            />
            <Button
              onClick={() => router.push("/biologicalAgeCalculator")}
              className="bg-gradient-to-r from-[#FF00F5] to-[#008EFF] text-white items-center  hidden lg:flex"
            >
              Know your Biological age!
            </Button>
          </div>

          <div className=" items-center gap-8 hidden lg:flex font-semibold">
            {webinarDisplay ? (
              <div className=" items-center gap-8 hidden lg:flex font-semibold">
                <Link href="/all-products"> Store</Link>
                <Button onClick={handleWebinarClick}>
                  Book a free consultation
                </Button>
              </div>
            ) : (
              <div className=" items-center gap-8 hidden lg:flex font-semibold">
                <Link href="/all-products"> Store</Link>
                <Link href="https://blog.thehealthspan.ai/"> Blogs</Link>
                {pathName === "/" ? (
                  <Link href="#joinProgram"> Programs</Link>
                ) : (
                  ""
                )}
                <Link href="/bonatra-ring-x1"> Smart Ring X1</Link>
              </div>
            )}

            <Button
              className={`bg-[#22C55E] ${isLoggedIn ? "hidden" : "flex"}`}
              onClick={handleLogin}
            >
              Login/Sign up
            </Button>
            <div
              className={`${
                isLoggedIn ? "flex" : "hidden"
              } hover:cursor-pointer`}
            >
              {isLoggedIn && (
                <ShoppingCart
                  onClick={() => {
                    logEvent("Products", "Cart");
                    (window as any).fbq("trackCustom", "Cart");
                    router.push("/cart");
                  }}
                  className={`${isLoggedIn ? "flex" : "hidden"}`}
                />
              )}

              {isLoggedIn && (
                <motion.div className="absolute top-0 right-10 flex justify-center items-center bg-[#22C55E] p-1 h-6 w-6 text-xs rounded-full text-white font-bold">
                  {cartQty || "0"}
                </motion.div>
              )}
            </div>
            <Hamburger className="bg-green-600" />
          </div>
          <div className="lg:hidden flex flex-row items-center gap-4">
            <div className="hover:cursor-pointer ">
              {isLoggedIn && (
                <ShoppingCart
                  onClick={() => {
                    logEvent("Products", "Cart");
                    (window as any).fbq("trackCustom", "Cart");
                    router.push("/cart");
                  }}
                  className={`${
                    isLoggedIn ? "flex" : "hidden"
                  } hover:cursor-pointer`}
                />
              )}

              {isLoggedIn && (
                <motion.div
                  // initial={{ y: -25, x: 18 }}
                  className="absolute top-0 right-6 flex justify-center items-center bg-[#22C55E] p-1 h-5 w-5 text-xs rounded-full text-white font-bold hover:cursor-pointer"
                >
                  {cartQty || "0"}
                </motion.div>
              )}
            </div>
            <Hamburger />
          </div>
        </nav>
        {burgerState && (
          <div className="h-screen flex-row flex flex-1  ">
            <NavMenu />
          </div>
        )}
      </Section>
    </div>
  );
};

export default Navbar;
